exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abamath-js": () => import("./../../../src/pages/abamath.js" /* webpackChunkName: "component---src-pages-abamath-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-certificate-js": () => import("./../../../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-code-is-sport-gallery-js": () => import("./../../../src/pages/code-is-sport-gallery.js" /* webpackChunkName: "component---src-pages-code-is-sport-gallery-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-coders-js": () => import("./../../../src/pages/coders.js" /* webpackChunkName: "component---src-pages-coders-js" */),
  "component---src-pages-compete-js": () => import("./../../../src/pages/compete.js" /* webpackChunkName: "component---src-pages-compete-js" */),
  "component---src-pages-curriculum-js": () => import("./../../../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-educators-js": () => import("./../../../src/pages/educators.js" /* webpackChunkName: "component---src-pages-educators-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keep-building-js": () => import("./../../../src/pages/keep-building.js" /* webpackChunkName: "component---src-pages-keep-building-js" */),
  "component---src-pages-new-challenge-js": () => import("./../../../src/pages/new-challenge.js" /* webpackChunkName: "component---src-pages-new-challenge-js" */),
  "component---src-pages-scoring-details-js": () => import("./../../../src/pages/scoring-details.js" /* webpackChunkName: "component---src-pages-scoring-details-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-privacy-js": () => import("./../../../src/pages/terms/privacy.js" /* webpackChunkName: "component---src-pages-terms-privacy-js" */),
  "component---src-pages-terms-virtual-events-js": () => import("./../../../src/pages/terms/virtual-events.js" /* webpackChunkName: "component---src-pages-terms-virtual-events-js" */),
  "component---src-pages-top-coders-js": () => import("./../../../src/pages/top-coders.js" /* webpackChunkName: "component---src-pages-top-coders-js" */),
  "component---src-pages-tournament-home-js": () => import("./../../../src/pages/tournament/home.js" /* webpackChunkName: "component---src-pages-tournament-home-js" */),
  "component---src-pages-tournament-instructions-js": () => import("./../../../src/pages/tournament/instructions.js" /* webpackChunkName: "component---src-pages-tournament-instructions-js" */),
  "component---src-pages-tournament-new-js": () => import("./../../../src/pages/tournament/new.js" /* webpackChunkName: "component---src-pages-tournament-new-js" */),
  "component---src-pages-tournament-pricing-js": () => import("./../../../src/pages/tournament/pricing.js" /* webpackChunkName: "component---src-pages-tournament-pricing-js" */),
  "component---src-pages-tournament-ten-new-js": () => import("./../../../src/pages/tournament/ten-new.js" /* webpackChunkName: "component---src-pages-tournament-ten-new-js" */),
  "component---src-templates-championship-event-js": () => import("./../../../src/templates/championship-event.js" /* webpackChunkName: "component---src-templates-championship-event-js" */)
}

